import request from '@/utils/requestAuth'
import requestBusiness from '@/utils/requestBusiness'
const info = '/service-auth-provider'
const auth = '/service-auth-provider/v1'
// 微信扫码登录
export function WxLogin(data) {
  return request({
    url: info + '/loginCallback?' + data,
    method: 'get'
  })
}

// 根据手机号绑定
export function bindByPhone(code, phone, unionId) {
  return request({
    url: auth + '/pc/register?code=' + code + '&phone=' + phone + '&unionId=' + unionId + '&openId=',
    method: 'post'
  })
}

// 登录
export function login(data) {
  return request({
    url: info + '/login',
    method: 'post',
    headers: {
      projectID: 5726455318996758
    },
    data
  })
}
// 请求手机号登录短信验证码
export function getLoginSms(data) {
  return request({
    url: info + '/v2/sms/login',
    method: 'post',
    data
  })
}
// 登录用户状态
export function queryLoginState(account) {
  return request({
    url: info + '/login/state?account=' + account,
    method: 'post'
  })
}

// 获取登陆用户信息
export function getInfo() {
  return request({
    url: info + '/user/info',
    method: 'get'
  })
}
// 登出
export function logout() {
  return request({
    url: info + '/logout',
    method: 'post'
  })
}
// 验证码
export function captcha() {
  return request({
    url: info + '/captcha',
    method: 'get'
  })
}
// 注册
export function register(data) {
  return request({
    url: info + '/v2/register',
    method: 'post',
    data
  })
}

// 获取用户名是否存在
export function getUserIsExist(account) {
  return request({
    url: auth + '/user/' + account + '/isExist',
    method: 'get'
  })
}
// 获取手机是否存在
export function getUserMobileIsExist(mobile) {
  return request({
    url: auth + '/user/mobile/' + mobile + '/duplicate',
    method: 'get'
  })
}

// 找回密码-手机方式
export function phoneFindPassword(data, account) {
  return request({
    url: auth + '/user/' + account + '/password/find',
    method: 'patch',
    data
  })
}

// 找回密码-申诉方式
export function appealFindPassword(data, account) {
  return request({
    url: auth + '/user/' + account + '/password/appeal',
    method: 'post',
    data
  })
}

// 用户修改密码
export function userUpdatePassword(data) {
  return request({
    url: auth + '/users/password',
    method: 'patch',
    data
  })
}

// 用户修改手机号
export function userUpdatePhone(data) {
  return request({
    url: auth + '/users/mobile',
    method: 'patch',
    data
  })
}

// 用户修改邮箱
export function userUpdateEmail(data) {
  return request({
    url: auth + '/users/email',
    method: 'patch',
    data
  })
}

// 用户修改头像
export function userUpdateAvatar(data) {
  return request({
    url: auth + '/users/avatar',
    method: 'patch',
    data
  })
}
// 修改用户昵称
export function userUpdateNickName(nickName) {
  return request({
    url: auth + '/user/nickname?nickName=' + nickName,
    method: 'patch'
  })
}
// 用户名称重复校验
export function duplicatecheck(account) {
  return request({
    url: auth + '/user/' + account + '/duplicate',
    method: 'get'
  })
}
// 手机号重复校验
export function mobileCheck(mobile, uId) {
  if (uId === '') {
    return request({
      url: auth + '/user/mobile/' + mobile + '/duplicate',
      method: 'get'
    })
  } else {
    return request({
      url: auth + '/user/mobile/' + mobile + '/duplicate?uId=' + uId,
      method: 'get'
    })
  }
}
// 邮箱重复校验
export function emailCheck(email, uId) {
  if (uId === '') {
    return request({
      url: auth + '/user/email/' + email + '/duplicate',
      method: 'get'
    })
  } else {
    return request({
      url: auth + '/user/email/' + email + '/duplicate?uId=' + uId,
      method: 'get'
    })
  }
}

// 手机号绑定请求短信
export function getBindPhoneSms(phone) {
  return request({
    url: info + '/v2/sms/app/bind?phone=' + phone,
    method: 'post'
  })
}
// 手机号绑定请求短信
export function getBindRegisterSms(data) {
  return request({
    url: info + '/v2/sms/register',
    method: 'post',
    data
  })
}

// 请求注册短信验证码
export function getRegisterSms(data) {
  return request({
    url: '/service-auth-provider/v2/sms/register',
    method: 'post',
    data
  })
}

// 用户修改初始账号
export function userUpdateAccount(account) {
  return request({
    url: auth + '/users/account/change?account=' + account,
    method: 'patch'
  })
}
// 查询当前活动
export function queryActivity() {
  return requestBusiness({
    url: '/service-point-provider/v1/goods/user/getPoster',
    method: 'get'
  })
}
// 查询用户积分
export function queryUserPoint() {
  return requestBusiness({
    url: '/service-point-provider/v1/point/getBycurrentAccount',
    method: 'get'
  })
}
