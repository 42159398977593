import router from './router'
import store from './store'
// import NProgress from 'nprogress' // progress bar
// import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
// import getPageTitle from '@/utils/get-page-title'

// NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/', '/callBack', '/register', '/userAgreement', '/registerUser', '/personal-info/find-password', '/personal-info/change-find-type', '/personal-info/phone-find', '/personal-info/update-password', '/personal-info/appeal-find'] // no redirect whitelist
// import { filesList } from '@/api/file'
router.beforeEach(async(to, from, next) => {
  // start progress bar
  // NProgress.start()

  // set page title
  // document.title = getPageTitle(to.meta.title)

  // determine whether the user has logged in
  // const hasToken = sessionStorage.desktopToken
  const hasToken = getToken()

  if (hasToken) {
    if (to.path === '/' || to.path === '/register' || to.path === '/registerUser' || to.path === '/callBack' || to.path === '/personal-info/find-password' || to.path === '/personal-info/change-find-type' || to.path === '/personal-info/phone-find' || to.path === '/personal-info/update-password' || to.path === '/personal-info/appeal-find') {
      // if is logged in, redirect to the home page
      next({ path: '/desktop' })
      // NProgress.done()
    } else {
      const hasGetUserInfo = store.getters.name
      if (hasGetUserInfo) {
        next()
      } else {
        try {
          // get user info
          const data = await store.dispatch('user/getInfo')

          next()
        } catch (error) {
          // await store.dispatch('user/resetToken')
          // Message.error(error || 'Has Error')
          // next(`/login?redirect=${to.path}`)
          // NProgress.done()
        }
        // 获取动态路由
        // const accessRoutes = await store.dispatch('permission/generateRoutes')
        // router.addRoutes(accessRoutes)
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else if (to.path === '/recharge' || to.path === '/rechargeAgreement' || to.path === '/authentication' || to.path === '/zfbPayment' || to.path === '/callBack') {
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next('/')
      // NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  // NProgress.done()
})
