import { reactive } from 'vue'
import arrays from '../utils/arrays.js'
import helper from '../utils/helper.js'
// import REGISTER from '../register/index.js'
import { ElMessage } from 'element-plus'
import { getRegionList } from '@/api/region'
import { queryPersonApp, isLink, updateWindowSize } from '@/api/application'
// 获取域列表
export function getRegionData(state) {
  return new Promise((resolve, reject) => {
    getRegionList().then((res) => {
      if (res.meta.status === 200) {
        state.regionOptions = res.data
        if (window.localStorage.regionId === undefined) {
          window.localStorage.regionId = state.regionOptions[0].id
          window.localStorage.regionValue = window.localStorage.regionId
          window.localStorage.regionName = state.regionOptions[0].regionName
          state.regionName = state.regionOptions[0].regionName
          window.localStorage.remark = state.regionOptions[0].regiremarkonName
          state.remark = state.regionOptions[0].remark
          state.regionId = state.regionOptions[0].id
        }
        res.data.forEach(item => {
          if (item.id == window.localStorage.regionValue) {
            state.regionName = item.regionName
            state.remark = item.remark
            state.regionId = item.id
          }
        })
        queryApp(state).then((loading) => {
          resolve({
            ...res,
            loading: loading
          })
        })
      } else {
        queryApp(state).then((loading) => {
          resolve({
            ...res,
            loading: loading
          })
        })
      }
      // resolve(res)
    }).catch(() => {
      queryApp(state)
      reject()
    })
  })
}
export function queryApp(state) {
  return new Promise((resolve, reject) => {
    state.applications = []
    queryPersonApp().then((response) => {
      if (response.data) {
        response.data.forEach(item => {
          const app = {}
          app.id = item.id
          app.linkId = item.linkId
          app.name = item.appName
          app.appName = item.appName
          app.icon = item.icon
          app.defaults = item.defaults
          app.x = item.x
          app.y = item.y
          app.width = item.width > 0 ? item.width : 0
          app.height = item.height > 0 ? item.height : 0
          app.page = item.page
          app.linkId = item.linkId
          app.visible = item.visible
          // app.link = !helper.ObjectIsNull(item.link) ? false : item.link
          if (helper.ObjectIsNull(item.link)) {
            app.link = false
          } else if (item.link === 'true') {
            app.link = true
          } else {
            app.link = false
          }
          app.selected = false
          state.applications.push(app)
        })
      }
      resolve(true)
      state.personAppComplete = true
    })
  })
}
export default {
  namespaced: true,
  state: {
    // 多域
    regionOptions: [],
    regionId: null,
    remark: '选择域',
    regionName: '选择域',
    // 应用
    name: 'XXXXXXXXXXXXXXX',
    dateFormat: 'YYYY-MM-DD',
    wallpaper: './wall/17.jpg',
    startMenu: false,
    sidebar: false,
    contextMenu: {
      type: 'wall',
      x: 0,
      y: 0,
      data: {}
    },
    personAppComplete: false,
    applications: [],
    tasks: reactive([]),
    usersManualId: null, // 查看用户手册应用的id
    usersManualCount: null,
    userAccountManualId: null,
    bareSSHBase64: null,
    bareSSHLoginType: null
  },

  mutations: {
    APP_INITT: (state) => {
      state.regionOptions = []
      state.applications = []
      localStorage.wallpaper = './wall/17.jpg'
      const wallpaper = helper.getLocalstorage('wallpaper', './wall/17.jpg')
      state.wallpaper = wallpaper
    },
    APP_INIT: (state) => {
      state.regionOptions = []
      state.applications = []
      localStorage.wallpaper = './wall/17.jpg'
      const wallpaper = helper.getLocalstorage('wallpaper', './wall/17.jpg')
      state.wallpaper = wallpaper
      getRegionData(state)
    },
    APP_UNINSTALL: (state, id) => {
      const i = arrays.findIndexById(state.tasks, id)
      if (i < 0) {
        const object = state.applications.filter(t => t.id == id)[0]
        console.log(object)
        const data = {
          linkId: object.linkId,
          link: 'false'
        }
        isLink(data).then((response) => {
          if (response.meta.status === 200) {
            // ElMessage.success('删除成功')
            // window.location.reload()
            queryApp(state)
          } else {
            ElMessage.error(response.meta.msg)
          }
        }).catch(error => {
          ElMessage.error(error.message)
        })
      }
    },
    APP_OPEN: (state, id) => {
      const i = arrays.findIndexById(state.tasks, id)
      if (i < 0) {
        const object = state.applications.filter(t => t.id == id)[0]
        const task = reactive({})
        task.title = object.name
        task.id = object.id
        task.linkId = object.linkId
        task.icon = object.icon
        task.x = object.x
        task.y = object.y
        task.width = object.width > 0 ? object.width : 0
        task.height = object.height > 0 ? object.height : 0
        task.page = helper.ObjectIsNull(object.page) ? '' : object.page
        task.min = false
        task.focus = true
        task.date = new Date()
        task.lastDate = new Date()
        if (task.id !== 3145878294710521) {
          localStorage.height = task.height
          localStorage.width = task.width
          localStorage.x = task.x
          localStorage.y = task.y
        }
        state.tasks.push(task)
      }
      state.tasks.forEach((item) => {
        if (item.id === id) {
          item.min = false
          item.focus = true
          item.lastDate = new Date()
        }
        if (item.id === 6372403323111393) {
          item.width = '1920'
          item.height = '1080'
        }
      })
    },
    APP_FOCUS: (state, id) => {
      const i = arrays.findIndexById(state.tasks, id)
      if (i < state.tasks.length) {
        const app = state.tasks[i]
        app.lastDate = new Date()
      }
    },
    APP_SORT_BY_DATE: (state) => {
      state.tasks.sort((a, b) => {
        return b.lastDate - a.lastDate
      })
    },
    APP_CLOSE: (state, id) => {
      if (id.linkId === undefined) {
        const i = arrays.findIndexById(state.tasks, id)
        if (i < state.tasks.length && i > -1) {
          arrays.deleteByIndex(state.tasks, i)
        }
      } else {
        const i = arrays.findIndexById(state.tasks, id.id)
        if (i < state.tasks.length && i > -1) {
          arrays.deleteByIndex(state.tasks, i)
        }
        if (id.id !== 6372403323111393) {
          const data = {
            linkId: id.linkId,
            height: localStorage.height,
            width: localStorage.width,
            x: localStorage.x,
            y: localStorage.y
          }
          updateWindowSize(data).then((response) => {
            if (response.meta.status === 200) {
              if (id.linkId != null) {
                state.applications.forEach(e => {
                  if (e.id == id.id) {
                    e.width = localStorage.width
                    e.height = localStorage.height
                    e.x = localStorage.x
                    e.y = localStorage.y
                  }
                })
              }
            }
          })
        }
      }
    },
    APP_MIN_SWITCH: (state, id) => {
      const i = arrays.findIndexById(state.tasks, id)
      if (i < state.tasks.length) {
        const app = state.tasks[i]
        if (app.focus == true) {
          app.min = true
          app.lastDate = state.tasks[state.tasks.length - 1].lastDate - 1
        } else if (app.min == true) {
          app.min = false
          app.lastDate = new Date()
        } else {
          app.min = true
          app.lastDate = new Date()
        }
      }
    },
    APP_CLICK_TASKBAR: (state, id) => {
      const i = arrays.findIndexById(state.tasks, id)
      if (i < state.tasks.length) {
        const app = state.tasks[i]
        if (app.focus == true) {
          app.min = true
          app.lastDate = state.tasks[state.tasks.length - 1].lastDate - 1
        } else if (app.min == true) {
          app.min = false
          app.lastDate = new Date()
        } else {
          app.min = false
          app.lastDate = new Date()
        }
      }
    },
    OPEN_START_MENU(state) {
      state.startMenu = !state.startMenu
    },
    SELECT_ICON(state, id) {
      state.applications.forEach(item => {
        if (id == item.id) {
          item.selected = true
        } else {
          item.selected = false
        }
      })
      if (state.startMenu) {
        state.startMenu = false
      }
    },
    SET_CONTEXT_MENU(state, data) {
      state.contextMenu.x = data.x
      state.contextMenu.y = data.y
      state.contextMenu.type = data.type
      state.contextMenu.data = data.data
    },
    CLEAN_CONTEXT_MENU(state) {
      state.contextMenu.x = -1
      state.contextMenu.y = -1
      state.contextMenu.type = ''
      state.contextMenu.data = {}
    },
    SET_WALLPAPER(state, url) {
      helper.setLocalstorage('wallpaper', url)
      state.wallpaper = url
    },
    SWITCH_SIDEBAR(state, flag) {
      if (helper.ObjectIsNull(flag)) {
        state.sidebar = flag
      } else {
        state.sidebar = !state.sidebar
      }
    },
    // 用户手册
    APP_UsersManual: (state, data) => {
      state.usersManualId = data.id
      state.usersManualCount = data.usersManualCount
      // const i = arrays.findIndexById(state.tasks, id)
      // if (i < state.tasks.length) {
      //   const app = state.tasks[i]
      //   app.lastDate = new Date()
      // }
    },
    APP_UserAccountManual: (state, data) => {
      state.userAccountManualId = data.id
    },
    BARE_SSH_BASE64: (state, base64) => {
      state.bareSSHBase64 = base64
    },
    BARE_SSH_LOGIN_TYPE: (state, loginType) => {
      state.bareSSHLoginType = loginType
    }
  },
  actions: {
    init(context) {
      context.commit('APP_INIT')
    },
    close(context, id) {
      context.commit('APP_CLOSE', id)
      context.commit('APP_SORT_BY_DATE')
    },
    focus(context, id) {
      context.commit('APP_FOCUS', id)
      context.commit('APP_SORT_BY_DATE')
    },
    minOrShow(context, id) {
      context.commit('APP_MIN_SWITCH', id)
      context.commit('APP_SORT_BY_DATE')
    },
    minSwitch(context, id) {
      context.commit('APP_MIN_SWITCH', id)
      context.commit('APP_SORT_BY_DATE')
    },
    clickTaskbar(context, id) {
      context.commit('APP_CLICK_TASKBAR', id)
      context.commit('APP_SORT_BY_DATE')
    },
    selectIcon(context, id) {
      context.commit('SELECT_ICON', id)
    },
    openApp(context, id) {
      context.commit('APP_OPEN', id)
      context.commit('APP_SORT_BY_DATE', id)
    },
    uninstall(context, id) {
      context.commit('APP_UNINSTALL', id)
      // context.commit("APP_INIT")
    },
    sidebarSwitch(context, flag) {
      context.commit('SWITCH_SIDEBAR', flag)
    },
    display(context) {
      context.commit('APP_OPEN', '2016259500456661')
      context.commit('APP_SORT_BY_DATE', '2016259500456661')
    },
    refresh() {
      window.location.reload()
    },
    help(context) {
      context.commit('APP_OPEN', '0000')
      context.commit('APP_SORT_BY_DATE', '0000')
    },
    // 用户手册
    usersManual(context, id) {
      context.commit('APP_UsersManual', id)
      context.commit('APP_SORT_BY_DATE')
    },
    userAccountManual(context, id) {
      context.commit('APP_UserAccountManual', id)
      context.commit('APP_SORT_BY_DATE')
    }
  }
}
